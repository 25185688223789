

body { margin: 0; }

h1 { font-family: 'Open Sans'; letter-spacing: -0.03em; margin: 0; }

#restaurant-og { width: 1200px; height: 630px; position: relative; overflow: hidden;
  #title-wrapper { position: absolute; left: 599px; top: 346px; z-index: 2; width: 520px; height: 186px; display: flex; }
  h1 { color: white; font-size: 50px; line-height: 58px; text-align: center; flex: 1; align-self: center; }
  #logo { position: absolute; top: 30px; left: 30px; z-index: 2; width: 150px; height: 150px; border-radius: 500px; border: 6px solid white; background: white; }
  #overlay { position: absolute; top: 0; left: 0; right: 0; bottom: 0; z-index: 1; }
  #background-wrapper { position: absolute; top: 0; left: 0; bottom: 0; right: 0;
    img { object-fit: cover; width: 100%; height: 100%; }
  }
}

#restaurant-catalog { width: 1080px; height: 1080px; position: relative; overflow: hidden;
  #overlay { position: absolute; top: 0; left: 0; right: 0; bottom: 0; z-index: 1; }
  #background-wrapper { position: absolute; top: 0; left: 0; bottom: 0; right: 0;
    img { object-fit: cover; width: 100%; height: 100%; }
  }
}

#city-og { width: 1200px; height: 630px; position: relative; overflow: hidden;
  h1 { position: absolute; left: 598px; top: 388px; z-index: 2; color: white; font-size: 60px; line-height: 50px; width: 525px; text-align: center;
    span { font-weight: normal; font-size: 40px; }
  }
  #logo { position: absolute; top: 100px; left: 100px; z-index: 2; width: 400px; height: 400px; }
  #overlay { position: absolute; top: 0; left: 0; right: 0; bottom: 0; z-index: 1; }
  #background-wrapper { position: absolute; top: 0; left: 0; bottom: 0; right: 0;
    img { object-fit: cover; width: 100%; height: 100%; }
  }
}

#promocode-og { width: 1200px; height: 630px; position: relative; overflow: hidden;
  #bubble { position: absolute; left: 598px; top: 382px; z-index: 2; width: 525px; height: 120px; display: flex; align-items: center; justify-content: center;
    h1 { color: white; text-align: center; font-weight: normal; font-size: 40px; line-height: 56px;
      &:first-line { font-size: 60px; font-weight: bold; }
    }
  }
  #logo { position: absolute; top: 100px; left: 100px; z-index: 2; width: 400px; height: 400px; }
  #overlay { position: absolute; top: 0; left: 0; right: 0; bottom: 0; z-index: 1; }
  #background-wrapper { position: absolute; top: 0; left: 0; bottom: 0; right: 0;
    img { object-fit: cover; width: 100%; height: 100%; }
  }
}

#mapMarker { height: 60px; width: 45px; }
